<template>
  <div>
    <el-data-table ref="table" v-bind="tableConfig" :columns="columns" style="margin-top: 10px;">
    </el-data-table>

      <el-dialog :title="title" :visible.sync="dialogFormVisible" :close-on-press-escape="false" :close-on-click-modal="false"  :before-close="closeDialog">
          <el-form :model="form" :rules="rules" ref="ruleUser">
              <el-form-item label='用户id' prop='id' :label-width="formLabelWidth" v-if="false">
                  <el-input placeholder='请输入用户id' clearable autocomplete="off" v-model='form.id' size="small"  />
              </el-form-item>
              <el-form-item label='姓名' prop='name' :label-width="formLabelWidth">
                  <el-input placeholder='请输入姓名' clearable autocomplete="off" v-model='form.name' size="small"  />
              </el-form-item>
              <el-form-item label='邮箱' prop='email' :label-width="formLabelWidth">
                  <el-input placeholder='请输入邮箱' clearable autocomplete="off" v-model='form.email' size="small"  />
              </el-form-item>
              <el-form-item label='密码' prop='password' :label-width="formLabelWidth" v-if="type=='update'">
                <el-input placeholder='请输入密码' clearable autocomplete="off" v-model='form.password' size="small"  />
              </el-form-item>
              <el-form-item label='积分' prop='integral' :label-width="formLabelWidth">
                  <el-input-number placeholder='请输入积分' clearable autocomplete="off" v-model='form.integral' :precision="0" :step="1" :min="0"></el-input-number>
              </el-form-item>
              <el-form-item label='折扣码' prop='discountcode' :label-width="formLabelWidth">
                  <el-input placeholder='请输入折扣码' clearable autocomplete="off" v-model='form.discountcode' size="small"  />
              </el-form-item>
              <el-form-item label='返利比' prop='rebateratio' :label-width="formLabelWidth">
                  <el-input-number placeholder='请输入返利比' clearable autocomplete="off" v-model='form.rebateratio' :precision="2" :step="0.05" :min="0" :max="1"></el-input-number>
              </el-form-item>
              <el-form-item label='用户等级' prop='dislevel' :label-width="formLabelWidth">
                  <el-select v-model="form.dislevel" placeholder="请选择">
                      <el-option
                              v-for="item in introlevelAll"
                              :key="item.id"
                              :label="item.level"
                              :value="item.id">
                      </el-option>
                  </el-select>
              </el-form-item>
              <el-form-item label='钱包金额' prop='wallet' :label-width="formLabelWidth">
                  <el-input-number placeholder='请输入钱包金额' clearable autocomplete="off" v-model='form.wallet' :precision="2" :step="1" :min="0"></el-input-number>
              </el-form-item>
<!--              <el-form-item label='头像' prop='avatar' :label-width="formLabelWidth">-->
<!--                  <el-input placeholder='请输入头像' clearable autocomplete="off" v-model='form.avatar' size="small"  />-->
<!--              </el-form-item>-->
<!--              <el-form-item label='电话' prop='phone' :label-width="formLabelWidth">-->
<!--                  <el-input placeholder='请输入电话' clearable autocomplete="off" v-model='form.phone' size="small"  />-->
<!--              </el-form-item>-->
<!--              <el-form-item label='用户等级' prop='level' :label-width="formLabelWidth">-->
<!--                  <el-input placeholder='请输入用户等级' clearable autocomplete="off" v-model='form.level' size="small"  />-->
<!--              </el-form-item>-->
<!--              <el-form-item label='风控id' prop='riskid' :label-width="formLabelWidth">-->
<!--                  <el-input placeholder='请输入风控id' clearable autocomplete="off" v-model='form.riskid' size="small"  />-->
<!--              </el-form-item>-->
<!--              <el-form-item label='邀请码' prop='invitecode' :label-width="formLabelWidth" v-if="type=='insert'">-->
<!--                  <el-input placeholder='请输入邀请码' clearable autocomplete="off" v-model='form.invitecode' size="small"  />-->
<!--              </el-form-item>-->
<!--              <el-form-item label='折扣码' prop='discountcode' :label-width="formLabelWidth" v-if="type=='insert'">-->
<!--                  <el-input placeholder='请输入折扣码' clearable autocomplete="off" v-model='form.discountcode' size="small"  />-->
<!--              </el-form-item>-->
<!--              <el-form-item label='推广链接' prop='referrallink' :label-width="formLabelWidth" v-if="type=='insert'">-->
<!--                  <el-input placeholder='请输入推广链接' clearable autocomplete="off" v-model='form.referrallink' size="small"  />-->
<!--              </el-form-item>-->
<!--              <el-form-item label='经销商(默认1)' prop='distributor' :label-width="formLabelWidth">-->
<!--                  <el-input placeholder='请输入经销商(默认1)' clearable autocomplete="off" v-model='form.distributor' size="small"  />-->
<!--              </el-form-item>-->
              <el-form-item label='用户角色' prop='usertype' :label-width="formLabelWidth">
                  <el-select v-model="form.usertype" placeholder="请选择">
                      <el-option
                              v-for="item in roleOptions"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value">
                      </el-option>
                  </el-select>
              </el-form-item>
<!--              <el-form-item label='支付类型' prop='paytype' :label-width="formLabelWidth">-->
<!--                  <el-input placeholder='请输入支付类型' clearable autocomplete="off" v-model='form.paytype' size="small"  />-->
<!--              </el-form-item>-->
              <el-form-item label='账号状态' prop='status' :label-width="formLabelWidth">
                  <el-select v-model="form.status" placeholder="请选择">
                      <el-option
                              v-for="item in statusOptions"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value">
                      </el-option>
                  </el-select>
              </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
              <el-button @click="closeDialog()">取 消</el-button>
              <el-button type="primary" @click="submitUser">确 定</el-button>
          </div>
      </el-dialog>
  </div>
</template>

<script>
  import { insertUser,updateUser,deleteUser,bacthDelUser,getSysRoleList,resetPwd,getIntrolevelAll } from "@/request/requests";
  import { MessageSuccess,MessageError,MessageInfo } from "@/util/util"
  export default {
    name: "user",
    data(){
        const signtypeOptions=[
            {
                label:"邮箱",
                value:"1"
            },
            {
                label:"谷歌",
                value:"2"
            },
            {
                label:"facebook",
                value:"3"
            }
        ]
        const roleOptions=[]
        const statusOptions=[
            {
                label:"启用",
                value:"1"
            },
            {
                label:"禁用",
                value:"0"
            },
        ]
      const columns = [
          {type: 'selection'},
          {prop: 'id',label: '用户id'},
          {prop: 'name',label: '姓名'},
          {prop: 'email',label: '邮箱'},
          {prop: 'avatar',label: '头像',formatter: row => this.imgState(row)},
          {prop: 'phone',label: '电话'},
          {prop: 'password',label: '密码'},
          {prop: 'sucessnum',label: '购买成功次数'},
          // {prop: 'country',label: '服务售卖国家'},
          {prop: 'services',label: '服务次数'},
          // {prop: 'buynum',label: '购买商品数量'},
          {prop: 'integral',label: '积分'},
          // {prop: 'level',label: '用户等级'},
          {prop: 'introlevel.level',label: '用户等级'},
          {prop: 'rebateratio',label: '返利比'},
          // {prop: 'riskid',label: '风控id'},
          {prop: 'invitecode',label: '邀请码'},
          {prop: 'discountcode',label: '折扣码'},
          {prop: 'referrallink',label: '推广链接'},
          {prop: 'referralnum',label: '推广人数'},
          // {prop: 'distributor',label: '经销商'},
          // {prop: 'dislevel',label: '分销等级'},
          {prop: 'wallet',label: '钱包金额'},
          {prop: 'signtype',label: '注册类型',formatter: row => this.signtypeState(row)},
          {prop: 'usertype',label: '用户角色',formatter: row => this.roleState(row)},
          // {prop: 'paytype',label: '支付类型'},
          {prop: 'status',label: '账号状态',formatter: row => this.statusState(row)},
          {prop: 'remark',label: '备注'},
          // {prop: 'payremark',label: '支付备注'},
          {prop: 'lastLogintime',label: '上次登录时间'},
          {prop: 'lastLoginip',label: '上次登录ip'},
          {prop: 'lastLogincountry',label: '上次登录国家'},
          {prop: 'thisLogintime',label: '本次登录时间'},
          {prop: 'thisLoginip',label: '本次登录ip'},
          {prop: 'thisLogincountry',label: '本次登录国家'},
          {prop: 'createTime',label: '创建时间'},
          {prop: 'updateTime',label: '更新时间'},
      ]
      const searchForm = [
          {
              type: 'date-picker',
              id: 'date',
              label: '时间筛选',
              el: {
                  type: 'daterange',
                  valueFormat: 'yyyy-MM-dd'
              },
              inputFormat: (row) => {
                  if (row.startdate && row.enddate) {
                      return [row.startdate, row.enddate]
                  }
              },
              outputFormat: (val) => {
                  if (!val) {
                      return ""
                  }
                  return {
                      startdate: val[0],
                      enddate: val[1]
                  }
              }
          },
          {
              type: 'select',
              id: 'usertype',
              label: '用户角色',
              el: {placeholder: '请输入用户角色', clearable: true},
              options:roleOptions
          },
          {
              type: 'input',
              id: 'name',
              label: '姓名',
              el: {placeholder: '请输入姓名', clearable: true},
          },
          {
              type: 'input',
              id: 'email',
              label: '邮箱',
              el: {placeholder: '请输入邮箱', clearable: true},
          },
          {
              type: 'input',
              id: 'id',
              label: '用户ID',
              el: {placeholder: '请输入用户ID', clearable: true},
          },
          {
              type: 'input',
              id: 'phone',
              label: '电话',
              el: {placeholder: '请输入电话', clearable: true},
          },
          // {
          //     type: 'input',
          //     id: 'level',
          //     label: '用户等级',
          //     el: {placeholder: '请输入用户等级', clearable: true},
          // },
          {
              type: 'input',
              id: 'invitecode',
              label: '邀请码',
              el: {placeholder: '请输入邀请码', clearable: true},
          },
          {
              type: 'input',
              id: 'discountcode',
              label: '折扣码',
              el: {placeholder: '请输入折扣码', clearable: true},
          },
          {
              type: 'input',
              id: 'referrallink',
              label: '推广链接',
              el: {placeholder: '请输入推广链接', clearable: true},
          },
          {
              type: 'select',
              id: 'status',
              label: '账号状态',
              el: {placeholder: '请输入账号状态', clearable: true},
              options:statusOptions
          },
          {
              type: 'select',
              id: 'signtype',
              label: '注册类型',
              el: {placeholder: '请输入注册类型', clearable: true},
              options:signtypeOptions
          },
          {
              type: 'input',
              id: 'lastLogincountry',
              label: '上次登录国家',
              el: {placeholder: '请输入上次登录国家', clearable: true},
          },
          {
              type: 'input',
              id: 'thisLogincountry',
              label: '本次登录国家',
              el: {placeholder: '请输入本次登录国家', clearable: true},
          },
      ]
      return {
        roleOptions,
        statusOptions,
        signtypeOptions,
        columns,
        userinfo:"",
        introlevelAll:[],
        tableConfig: {
          searchForm,
          url: 'user/list',
          dataPath: 'data.list',
          totalPath: 'data.total',
          paginationSize:10,
          extraButtons: [
            {
              type: 'primary',
              text: '编辑',
              icon: 'el-icon-edit-outline',
              show:()=>{return this.hasRole('user:update')},
              atClick: async row => {
                this.handleEdit(row)
                return false
              },
            },
            // {
            //   type: 'primary',
            //   text: '删除',
            //   icon: 'el-icon-delete',
            //   show:()=>{return this.hasRole('user:delete')},
            //   atClick: async row => {
            //     this.handleDelete(row)
            //     return false
            //   },
            // },
              {
                  type: 'primary',
                  text: '重置密码',
                  icon: 'el-icon-refresh-right',
                  atClick: async row => {
                      this.handleReset(row)
                      return false
                  },
              },
          ],
          headerButtons: [
            {
              text: '新增',
              type: 'primary',
              show:()=>{return this.hasRole('user:insert')},
              atClick: async () => {
                this.handleInsert()
                return false
              },
            },
            // {
              //   text: '批量删除',
              //   type: 'primary',
              //   show:()=>{return this.hasRole('user:batchDelete')},
              //   disabled: selected => selected.length < 1,
              //   atClick: selected => {
              //     let ids = selected.map(item => item.id)
              //     this.Batch('delete', ids)
              //     return false
              //   },
              // },
          ],
          hasDelete: false,
          hasEdit: false,
          hasOperation: true,
          hasNew: false,
          operationAttrs: {
            width: '150px',
            fixed: 'right',
          },
        },
        form:this.initForm(),
        title:"",
        type:"",
        dialogFormVisible:false,
        formLabelWidth:"100px",
        rules: {
            id :[
                {required: true, message: '请输入用户id', trigger: 'blur'},],
            name :[
                {required: true, message: '请输入姓名', trigger: 'blur'},],
            email :[
                {required: true, message: '请输入邮箱', trigger: 'blur'},],
            avatar :[
                {required: true, message: '请输入头像', trigger: 'blur'},],
            phone :[
                {required: true, message: '请输入电话', trigger: 'blur'},],
            // password :[
            //     {required: true, message: '请输入密码', trigger: 'blur'},],
            sucessnum :[
                {required: true, message: '请输入购买成功次数', trigger: 'blur'},],
            country :[
                {required: true, message: '请输入服务售卖国家(购买商品所属国家  多个)', trigger: 'blur'},],
            services :[
                {required: true, message: '请输入服务次数(下单次数)', trigger: 'blur'},],
            buynum :[
                {required: true, message: '请输入购买商品数量(购买成功商品数量)', trigger: 'blur'},],
            integral :[
                {required: true, message: '请输入积分', trigger: 'blur'},],
            level :[
                {required: true, message: '请输入用户等级', trigger: 'blur'},],
            riskid :[
                {required: true, message: '请输入风控id', trigger: 'blur'},],
            invitecode :[
                {required: true, message: '请输入邀请码', trigger: 'blur'},],
            discountcode :[
                {required: true, message: '请输入折扣码', trigger: 'blur'},],
            referrallink :[
                {required: true, message: '请输入推广链接', trigger: 'blur'},],
            distributor :[
                {required: true, message: '请输入经销商(默认1)', trigger: 'blur'},],
            rebateratio :[
                {required: true, message: '请输入返利比', trigger: 'blur'},],
            dislevel :[
                {required: true, message: '请输入分销等级', trigger: 'blur'},],
            wallet :[
                {required: true, message: '请输入钱包金额', trigger: 'blur'},],
            signtype :[
                {required: true, message: '请输入注册类型', trigger: 'blur'},],
            usertype :[
                {required: true, message: '请输入用户角色', trigger: 'blur'},],
            paytype :[
                {required: true, message: '请输入支付类型', trigger: 'blur'},],
            status :[
                {required: true, message: '请输入账号状态', trigger: 'blur'},],
            remark :[
                {required: true, message: '请输入备注', trigger: 'blur'},],
            payremark :[
                {required: true, message: '请输入支付备注', trigger: 'blur'},],
            lastLogintime :[
                {required: true, message: '请输入上次登录时间', trigger: 'blur'},],
            lastLoginip :[
                {required: true, message: '请输入上次登录ip', trigger: 'blur'},],
            lastLogincountry :[
                {required: true, message: '请输入上次登录国家', trigger: 'blur'},],
            thisLogintime :[
                {required: true, message: '请输入本次登录时间', trigger: 'blur'},],
            thisLoginip :[
                {required: true, message: '请输入本次登录ip', trigger: 'blur'},],
            thisLogincountry :[
                {required: true, message: '请输入本次登录国家', trigger: 'blur'},],
            createTime :[
                {required: true, message: '请输入创建时间', trigger: 'blur'},],
            updateTime :[
                {required: true, message: '请输入更新时间', trigger: 'blur'},],
            ext1 :[
                {required: true, message: '请输入扩展字段', trigger: 'blur'},],
            ext2 :[
                {required: true, message: '请输入扩展字段', trigger: 'blur'},],
            ext3 :[
                {required: true, message: '请输入扩展字段', trigger: 'blur'},],
            ext4 :[
                {required: true, message: '请输入扩展字段', trigger: 'blur'},],
        },
      }
    },
    methods:{
        imgState(row){
            return <img style="height:100px;" src={row.avatar}/>
        },
        statusState(row){
            let data
            this.statusOptions.forEach(r=>{
                if (r.value==row.status){
                    data=r.label
                }
            })
            return data
        },
        roleState(row){
            let data
            this.roleOptions.forEach(r=>{
                if (r.value==row.usertype){
                    data=r.label
                }
            })
            return data
        },
        signtypeState(row){
            let data
            this.signtypeOptions.forEach(r=>{
                if (r.value==row.signtype){
                    data=r.label
                }
            })
            return data
        },
        handleReset(row) {
            this.$confirmel('此操作将重置当前用户密码, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.resetPwdinfo(row)
            }).catch(() => {
                MessageInfo("已取消");
            });
        },
        async resetPwdinfo(row) {
            let data={
                userId:row.id
            }
            let res = await resetPwd(data)
            if (res.status == "200") {
                MessageSuccess(res.msg)
            } else {
                MessageError(res.msg)
            }
            this.$refs.table.getList()
        },
        hasRole(val){
            return this.hasPerm(val)
        },
      initForm() {//初始数据
        return {
            id : "", // 用户id
            name : "", // 姓名
            email : "", // 邮箱
            avatar : "", // 头像
            phone : "", // 电话
            password : "", // 密码
            sucessnum : "", // 购买成功次数
            country : "", // 服务售卖国家(购买商品所属国家  多个)
            services : "", // 服务次数(下单次数)
            buynum : "", // 购买商品数量(购买成功商品数量)
            integral : "", // 积分
            level : "", // 用户等级
            riskid : "", // 风控id
            invitecode : "", // 邀请码
            discountcode : "", // 折扣码
            referrallink : "", // 推广链接
            distributor : "", // 经销商(默认1)
            rebateratio : "", // 返利比
            dislevel : "", // 分销等级
            wallet : "", // 钱包金额
            signtype : "", // 注册类型
            usertype : "", // 用户角色
            paytype : "", // 支付类型
            status : "", // 账号状态
            remark : "", // 备注
            payremark : "", // 支付备注
            lastLogintime : "", // 上次登录时间
            lastLoginip : "", // 上次登录ip
            lastLogincountry : "", // 上次登录国家
            thisLogintime : "", // 本次登录时间
            thisLoginip : "", // 本次登录ip
            thisLogincountry : "", // 本次登录国家
            createTime : "", // 创建时间
            updateTime : "", // 更新时间
            ext1 : "", // 扩展字段
            ext2 : "", // 扩展字段
            ext3 : "", // 扩展字段
            ext4 : "", // 扩展字段
        }
      },
      handleEdit(row) {
        console.log(row)
        this.form = row
        this.openDialog();
        this.title = "修改信息"
        this.type = "update"
      },
      handleDelete(row) {
          this.$confirmel('此操作将永久删除该文件, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
          }).then(() => {
              this.operatorDeleteUser(row)
          }).catch(() => {
              MessageInfo("已取消删除");
          });
      },
      async operatorDeleteUser(row) {
        let res = await deleteUser(row.id)
        if (res.status == "200") {
          MessageSuccess(res.msg)
        } else {
          MessageError(res.msg)
        }
        this.$refs.table.getList()
      },
      handleInsert() {
        this.openDialog();
        this.title = "新增"
        this.type = "insert"
      },
      async Batch(type, ids) {
        if (type == "delete") {
          let res = await bacthDelUser(ids)
          if (res.status == "200") {
            MessageSuccess(res.msg)
          } else {
            MessageError(res.msg)
          }
        }
        this.$refs.table.getList()
      },
      openDialog() {
        this.dialogFormVisible = true
      },
      closeDialog() {
        this.dialogFormVisible = false
        this.form = this.initForm();
        this.$refs.ruleUser.clearValidate();
        this.$refs.table.getList()
      },
      async submitUser() {
        this.$refs.ruleUser.validate((valid) => {
          if (valid) {
            this.operatorUser()
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      async operatorUser() {
        let res;
        if (this.type == "insert") {
          res = await insertUser(this.form)
          if (res.status == "200") {
            MessageSuccess(res.msg)
            this.closeDialog()
          } else {
            MessageError(res.msg)
          }
        } else if (this.type == "update") {
          res = await updateUser(this.form)
          if (res.status == "200") {
            MessageSuccess(res.msg)
            this.closeDialog()
          } else {
            MessageError(res.msg)
          }
        }
        this.$refs.table.getList()
      },
        //获取所有角色
        async getAllRole() {
            this.roleOptions=[]
            let res=await getSysRoleList()
            if (res.status=="200"){
                res.data.list.forEach(r=>{
                    let data={
                        label:r.roleName,
                        value:r.id,
                    }
                    this.roleOptions.push(data)
                })
                this.tableConfig.searchForm[1].options=this.roleOptions
            } else {
                MessageError(res.msg)
            }
        },
      getUserInfo(){
        let deadline = sessionStorage.getItem('deadline');
        let userInfo = sessionStorage.getItem("userInfo");
        let token = sessionStorage.getItem('token');
        if(!deadline||!userInfo||!token) {
          this.removeInfo()
          MessageError("请先登录！")
          this.$router.push({ path: "/login" });
          return false
        }else{
          let now = new Date().getTime();
          if (deadline<now){
            this.removeInfo()
            MessageError("请先登录！")
            this.$router.push({ path: "/login" });
            return false
          }
        }
        return userInfo;
      },
      removeInfo(){
        sessionStorage.removeItem("userInfo");
        sessionStorage.removeItem("deadline");
        sessionStorage.removeItem("token");
      },
      async getIntrolevelAllInfo() {
          let res = await getIntrolevelAll()
          if (res.status == "200") {
              this.introlevelAll=res.data
          } else {
              MessageError(res.msg)
          }
      }
    },
    created() {
      let userInfo=this.getUserInfo()
      if (userInfo){
        this.userinfo=JSON.parse(userInfo)
        this.tableConfig.axiosConfig={
          headers: {
            'Authorization': 'X-KILOGOD-AUTHORIZATION '+sessionStorage.getItem('token'),

          }
        }
        this.getAllRole()
        if(this.$route.query.id){
            this.tableConfig.url="user/list?id="+this.$route.query.id
        }
        this.getIntrolevelAllInfo()
      }
    }
  }
</script>

<style scoped>

</style>
